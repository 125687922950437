import { DatePipe } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { isEqual } from 'lodash-es';

import { Period, PopularPeriod } from '@@core/models/utils/popular-period.model';

@Injectable({
	providedIn: 'root'
})
export class PopularPeriodService {
	datePipe = new DatePipe('en-US');
	readonly #dateAdapter = inject(DateAdapter) as DateAdapter<Date>;

	createPeriod(start: Date, end: Date, name?: string): PopularPeriod {
		const label = name || `${this.datePipe.transform(start, 'MMM dd')} - ${this.datePipe.transform(end, 'MMM dd, yyyy')}`;
		const period = {
			from: start.valueOf(),
			to: this.#dateAdapter.addCalendarDays(end, 1).valueOf() - 1
		};
		return {
			name: label,
			displayName: label,
			period,
			isCustom: false,
			isBuiltIn: false,
		};
	}

	findOrCreatePopularPeriod(period: Period, popularPeriods: PopularPeriod[]): PopularPeriod {
		const popularPeriod = popularPeriods.find(pp => isEqual(pp.period, period));

		if (popularPeriod) {
			return popularPeriod;
		}

		const start = new Date(period.from);
		const end = new Date(period.to);

		return this.createPeriod(start, end);
	}
}
